import refreshToken from '@/plugins/refresh-token'
import { api } from './main'

const checkImportFileCustomer = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'customer/checkimport',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('check file import customer list error : ', err)

      return {}
    })

  return response
}

const checkImportFileChecking = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'checking/checkimport',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('check file import checking list error : ', err)

      return {}
    })

  return response
}

const checkImportFileDrug = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'drug/checkdrugimport',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('check file import checking list error : ', err)

      return {}
    })

  return response
}

const checkImportFileEquipment = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'drug/checkequipmentimport',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('check file import checking list error : ', err)

      return {}
    })

  return response
}

const checkImportFileCourse = async (path, body) => {
  //await refreshToken()
  const response = await api
    .post({
      path,
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('check file import checking list error : ', err)

      return {}
    })

  return response
}

export default {
  checkImportFileCustomer,
  checkImportFileChecking,
  checkImportFileDrug,
  checkImportFileEquipment,
  checkImportFileCourse,
}
